import { twMerge } from 'tailwind-merge'
import { clsx } from 'clsx'
import numeral from 'numeral'
import moment from 'moment'

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export function htmlToText(html) {
  var temp = document.createElement('div');
  temp.innerHTML = html;
  return temp.textContent; 
}

export function currency(value) {
  return numeral(value).format('$0,0.00')
}

export function date(value) {
  return moment(value, 'MM/DD/YYYY').format('dddd, MMMM D, YYYY')
}

export function deepClone(obj) {
  if (obj === null || typeof obj !== 'object') return obj
  if (obj instanceof Date) return new Date(obj.getTime())
  if (Array.isArray(obj)) return obj.map(item => deepClone(item))
  if (obj instanceof Object) {
    const copy = {};
    Object.keys(obj).forEach(key => {
      copy[key] = deepClone(obj[key]);
    });
    return copy;
  }
  throw new Error("Unable to copy obj! Its type isn't supported.");
}
